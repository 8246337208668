import React from "react";
import { Button } from "@views/KlienKami/Components";
import { ourClient } from "@utils/static-data";
import styles from '@views/KlienKami/KlienKami.module.scss'

type ButtonAbcProps = {
    active: string
    setActive: React.Dispatch<React.SetStateAction<string>>
}

export default function ButtonAbc({ active, setActive }: ButtonAbcProps) {
  const letters: string[] = Object.keys(ourClient);

  const onClick = (e: React.MouseEvent, letter: string) => {
    e.preventDefault();
    setActive(letter);
  };

  return (
    <div className={styles.abcWrapper}>
      {letters.map(function (letter: string, index: number) {
        return (
          <Button
            activeLetter={active}
            letter={letter}
            onClick={onClick}
            key={index}
          />
        );
      })}
    </div>
  );
}
