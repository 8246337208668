import React from "react";
import styles from "@views/KlienKami/KlienKami.module.scss";
import { animate, quadEaseInOut } from "@helper/animate";

type ScrollButtonProps = {
  clientRef: React.RefObject<HTMLDivElement>;
};

export default function ScrollButton({ clientRef }: ScrollButtonProps) {
  /**
   * Scroll button on click
   * 
   * @param e React.MouseEvent
   * @param direction direction scroll wheter up or down
   */
  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: "up" | "down"
  ) => {
    e.preventDefault();

    if (clientRef && clientRef.current) {
      const current = clientRef.current;
      const scroll = direction === "up" ? -20 : 20;

      animate({
        duration: 300,
        timing: quadEaseInOut,
        draw: function (progress: number) {
          current.scrollTop = current.scrollTop + progress * scroll;
        },
      });
    }
  };

  return (
    <div className={styles.navButton}>
      <button onClick={e => onClick(e, "up")}>
        <img src={require("@assets/images/icon/icon-arrow-up.svg")} />
      </button>
      <button onClick={e => onClick(e, "down")}>
        <img src={require("@assets/images/icon/icon-arrow-down.svg")} />
      </button>
    </div>
  );
}
