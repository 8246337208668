import React, { useState } from "react";
import Layout from "@components/Layout";
import HeaderHero from "@views/KlienKami/HeaderHero";
import ButtonAbc from "@views/KlienKami/ButtonAbc";
import CardClient from "@views/KlienKami/CardClient";
import { KlienKamiPage } from "src/types";
import { getUserScreen } from '@helper/index'

import styles from './KlienKami.module.scss'

export default function KlienKami(props: KlienKamiPage) {
  const { desktopImg, mobileImg } = props.data;
  const [letterActive, setLetterActive] = useState<string>("a");
  const userWidth = getUserScreen()
  const noFooter = userWidth <= 1023.98

  /**
   * hero images Array object
   * @types FluidObject[]
   */
  const heroImages = [
    mobileImg.childImageSharp.fluid,
    {
      ...desktopImg.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <Layout noFooter={noFooter}>
      <HeaderHero heroImage={heroImages} />
      <div className={styles.contents}>
        <ButtonAbc active={letterActive} setActive={setLetterActive} />
        <CardClient active={letterActive} />
      </div>
    </Layout>
  );
}
