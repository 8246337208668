import React from "react";
import Img, { FluidObject } from "gatsby-image";
import styles from '@views/KlienKami/KlienKami.module.scss'

type Props = {
  heroImage: FluidObject[];
};

export default function HeaderHero({ heroImage }: Props) {
  return (
    <header className={styles.header}>
      <div className={styles.img}>
        <Img
          fluid={heroImage}
          alt="hero-klien"
          style={{ width: 100 + "%", height: 100 + "%" }}
        />
      </div>
      <div className={styles.titles}>
        <h1>
          <span>Dibawah ini adalah daftar klien{" "}</span>
          <span>yang telah order di kami{" "}</span>
          <span>sesuai huruf abjad</span>
        </h1>
      </div>
    </header>
  );
}
