import React from "react";
import { graphql } from "gatsby";
import { KlienKamiPage } from 'src/types'
import KlienKami from "@views/KlienKami";

/**
 * QUERIES FOR KLIEN KAMI PAGE
 * 
 * Query graphql untuk get image pada folder asset
 * query ini digunakan untuk image fluid pada
 * gatsby-image
 */
export const pageQuery = graphql`
  query KlienKami {
    # QUERY DESKTOP IMAGE
    desktopImg: file(
      relativePath: { eq: "bg/desktop/klien-hero.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # QUERY MOBILE IMAGE
    mobileImg: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function klienKami(props: KlienKamiPage) {
  return <KlienKami {...props} />;
}
