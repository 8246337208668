import { useState, useEffect } from "react";
/**
 *
 * @param {string} link - A YouTube video link/url
 * @returns {string} - YouTube video ID
 */
export const getYouTubeID = (link: string): string => {
  const YTID = link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return YTID[2] !== undefined ? YTID[2].split(/[^0-9a-z_-]/i)[0] : YTID[0];
};

/**
 *
 * @param {string} link - A YouTube video link/url
 * @param {string} quality - Choose quality of thumbnail (maxres, hq, mq, sq)
 * @returns {string} - Image url of video thumbnail
 */
export const getYouTubeThumbnail = (link: string, quality: string): string => {
  let video_id = getYouTubeID(link);

  let video_thumbnail = `https://img.youtube.com/vi/${video_id}/${quality}default.jpg`;

  return video_thumbnail;
};

/**
 *
 * @param {string} param - URL parameters that you want to get the value.
 * @returns {string} - Value of params that you want to get.
 */
export const getURLParam = (param: string): string => {
  const qs = window.location.search;
  const url_params = new URLSearchParams(qs);
  const get_params = url_params.get(param) ? url_params.get(param) : "";
  return decodeURIComponent(get_params as string).replace(/[^\w\s]/gi, "");
};

/**
 * @returns {number} - value of size user width.
 */
export const getUserScreen = () => {
  const [width, setWidth] = useState<number>(0);
  const handleWindowsizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    handleWindowsizeChange()
    
    window.addEventListener("resize", handleWindowsizeChange);
    return () => window.removeEventListener("resize", handleWindowsizeChange);
  });

  return width;
};
