import React, { createRef } from "react";
import { ourClient } from "@utils/static-data";
import styles from "@views/KlienKami/KlienKami.module.scss";
import { getUserScreen } from "@helper/index";
import ScrollButton from "./Components/ScrollButton";

type CardClientProps = {
  active: string;
};

export default function CardClient({ active }: CardClientProps) {
  const userWidth = getUserScreen();
  const ref = createRef<HTMLDivElement>();

  const clients: string[] = [...ourClient[active]];
  const half: number = Math.ceil(clients.length / 2);

  const left: string[] = clients.splice(0, half);
  const right: string[] = clients.splice(-half);

  return (
    <div className={styles.clients}>
      <div ref={ref} className={styles.row}>
        <div className={styles.col}>
          {left.map(function (val: string, index: number) {
            return <p key={index}>{val}</p>;
          })}
        </div>
        <div className={styles.col}>
          {right.map(function (val: string, index: number) {
            return <p key={index}>{val}</p>;
          })}
        </div>
      </div>
      {userWidth <= 1023.98 && (
        <ScrollButton clientRef={ref} />
      )}
    </div>
  );
}
