import React from "react";
import styles from "@views/KlienKami/KlienKami.module.scss";

type ButtonProps = {
  letter: string;
  activeLetter: string;
  onClick: Function;
};

export default function Button(props: ButtonProps) {
  const { letter, activeLetter, onClick } = props;
  const className =
    letter === activeLetter
      ? `${styles.letter} ${styles.active}`
      : `${styles.letter}`;

  return (
    <button onClick={(e: React.MouseEvent) => onClick(e, letter)} className={className}>
      {letter}
    </button>
  );
}
